<template>
  <div class="my_container table">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1"
        disable-sort
    >
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="10"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import {get_created_page_list} from "@/api/admin";

export default {
  data () {
    return {
      page_loading: true,
      page: 1,
      itemsPerPage: 10,
      pageCount: 1,
      headers: [
        {
          text: 'Page Name',
          align: 'center',
          value: 'page_name',
        },
      ],
      desserts: [],
    }
  },
  methods: {
    pages_list() {
      this.desserts = []
      get_created_page_list().then(res => {
        this.desserts = res.pages.data;
      })
      .catch(() => {});
    },
  },
  mounted() {
    this.pages_list()
  }
}
</script>

